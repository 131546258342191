import { useState, useEffect, useCallback } from 'react';
import { EPOCH_START_LOVE } from '@/constants';

const INTERVAL_TIME = 1000;
const YEAR_PER_SECONDS = 86400*365;
const DAY_PER_SECONDS = 86400;
const HOUR_PER_SECONDS = 3600;
const MINUTE_PER_SECONDS = 60;

const getEpochTime = () => Math.floor(new Date().getTime() / 1000);

function useDateCountup() {
  const [years, setYears] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [currentEpochTime, setCurrentEpochTime] = useState(() => getEpochTime());

  const calculateTimeLeft = useCallback(() => {
    const TIME_LEFT = currentEpochTime - EPOCH_START_LOVE;

    const yearsLeft = Math.floor(TIME_LEFT / YEAR_PER_SECONDS);
    const daysLeft = Math.floor((TIME_LEFT % YEAR_PER_SECONDS) / DAY_PER_SECONDS);
    const hoursLeft = Math.floor((TIME_LEFT % DAY_PER_SECONDS) / HOUR_PER_SECONDS);
    const minutesLeft = Math.floor(((TIME_LEFT % DAY_PER_SECONDS) % HOUR_PER_SECONDS) / MINUTE_PER_SECONDS);
    const secondsLeft = Math.floor(((TIME_LEFT % DAY_PER_SECONDS) % HOUR_PER_SECONDS) % MINUTE_PER_SECONDS);
    const currentEpoch = getEpochTime();

    setYears(yearsLeft);
    setDays(daysLeft);
    setHours(hoursLeft);
    setMinutes(minutesLeft);
    setSeconds(secondsLeft);
    setCurrentEpochTime(currentEpoch);
  }, [currentEpochTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      calculateTimeLeft();
    }, INTERVAL_TIME);

    return () => clearInterval(interval);
  }, [calculateTimeLeft]);

  return { years, days, hours, minutes, seconds};
}

export default useDateCountup;
