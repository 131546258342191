import {React, useRef} from 'react';
import { styBoxWrapper } from './styles';
import Tick from '@assets/sound/tick.mp3';
import Swish1 from '@assets/sound/Swish1.mp3';
import Duck from '@assets/sound/duck.mp3';

var $ = require( "jquery" );
const Swal = require('sweetalert2')
const textConfig = {
  text1: "Hihi em!",
  text2: "Anh có điều này muốn hỏi em, nhớ phải trả lời thật lòng nha!",
  text3: "Làm Vợ anh nhé? ❤️",
  text4: "Nếu em ko trả lời, là đồng ý làm Vợ anh rùi đó nha! 🥰",
  text5: "Anh mơ à?",
  text6: "Em đồng ý 😘",
  text7: "Lý do em yêu anh là gì thế?",
  text8: "Gửi anh 😘",
  text9: "Vì anh quá đẹp trai❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️❤️",
  text10: "Anh biết mà ^^ Yêu em!",
  text11:
    "Mình cưới luôn trong năm nay nhé? ❤️",
  text12: "Okay luôn!",
  text13: "Dear My Love",
};

function DearLoveSection() {  

function acceptLove(){
  var audio = new Audio(Tick);
  audio.play();
  Swal.fire({
    title: textConfig.text7,
    html: true,
    width: 900,
    padding: "3em",
    html: "<input type='text' class='form-control' id='txtReason'  placeholder='Nhập câu trả lời ở đây...'>",
    background: '#fff',
    showCancelButton: false,
    confirmButtonColor: "#f14e95",
    cancelButtonColor: "#f14e95",
    confirmButtonText: textConfig.text8,
  }).then((result) => {
    if (result.value) {
      Swal.fire({
        width: 900,
        confirmButtonText: textConfig.text12,
        background: '#fff',
        title: textConfig.text10,
        text: textConfig.text11,
        confirmButtonColor: "#f14e95",
        onClose: () => {
          console.log("on close popup");
        },
      });
    }
  });

  $("#txtReason").focus(function () {
    var handleWriteText = setInterval(function () {
      textGenerate();
    }, 10);
    $("#txtReason").blur(function () {
      clearInterval(handleWriteText);
    });
  });
}

function textGenerate() {
  var n = "";
  var text = " " + textConfig.text9;
  var a = Array.from(text);
  var textVal = $("#txtReason").val() ? $("#txtReason").val() : "";
  var count = textVal.length;
  if (count > 0) {
    for (let i = 1; i <= count; i++) {
      n = n + a[i];
      if (i == text.length + 1) {
        $("#txtReason").val("");
        n = "";
        break;
      }
    }
  }
  $("#txtReason").val(n);
}

function openLetter() {
  var current = document.getElementsByClassName("envelopeActive");
  if (current.length > 0) { 
    current[0].className = current[0].className.replace("envelopeActive", "");
  }
  var n = 0;

  document.querySelector('#content-no').addEventListener('mousemove',function () {
    if (n < 1) switchButton();
    if (n > 1) moveButton();
    n++;
  });
}

// switch button position
function switchButton() {
  var audio = new Audio(Duck);
  audio.play();
  var leftNo = $("#content-no").css("left");
  var topNO = $("#content-no").css("top");
  var leftY = $("#content-yes").css("left");
  var topY = $("#content-yes").css("top");
  $("#content-no").css("left", leftY);
  $("#content-no").css("top", topY);
  $("#content-yes").css("left", leftNo);
  $("#content-yes").css("top", topNO);
}
// move random button póition
function moveButton() {
  
  var audio = new Audio(Swish1);
  audio.play();
  if (window.innerWidth <= 600) {
    var x = Math.random() * 300;
    var y = Math.random() * 500;
  } else {
    var x = Math.random() * 500;
    var y = Math.random() * 500;
  }
  var left = x + "px";
  var top = y + "px";
  $("#content-no").css("left", left);
  $("#content-no").css("top", top);
}

var n = 0;
$("#content-no").mousemove(function () {
  if (n < 1) switchButton();
  if (n > 1) moveButton();
  n++;
});
$("#content-no").click(() => {
  if (window.innerWidth >= 900) switchButton();
});

const contentNo = useRef(null);
const contentYes = useRef(null);

  return (
    <div id="fh5co-couple" css={styBoxWrapper}>
      <div className="container" >
        <div className="row" >
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2 className="main-font">Dear My Love</h2>
            
            <button type="button" class="button-74" onClick={() => openLetter()}>Click to Open</button>
          </div>
        </div>
      </div>
      <section class="rsvp" id="rsvp">
      
  <div class="container">

      <div id="envelope" class="envelopeActive">
          <div class="envelope_front">
              <div class="env_top_top"></div>
          </div>
          <div class="envelope_back">
              <div class="env_top"></div>
          </div>

          <div class="paper">

              <div class="paper_title">Làm vợ anh nhé?</div>
              <div class="paper_title">❤️</div>

              <div id="div_block_1">
                  <div id="crush" class="crush-wrapper">
                      
                      <div id="crush-bg"></div>
                          <button id="content-yes" type="button" class="button-71 btn btn-lg" ref={contentYes} onClick={()=> acceptLove()}>
                          Em đồng ý 😘 
                            </button>
                            <button id="content-no" type="button" class="button-83" ref={contentNo}>
                            Anh mơ à?
                            </button>
                    </div>


                  <p class="paper-desc">Nếu em ko trả lời là đồng ý làm Vợ anh rùi đó nha :v</p>
              </div>
          </div>

      </div>
  </div>
</section>
    </div>
  );
}


export default DearLoveSection;
