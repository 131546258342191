import Story1 from '@assets/images/st-hana-1.jpg';
import Story2 from '@assets/images/st-hana-2.jpg';
import Story3 from '@assets/images/st-hana-3.jpg';
import Story4 from '@assets/images/st-hana-4.jpg';
import Story5 from '@assets/images/st-hana-5.jpg';
import Story6 from '@assets/images/st-hana-6.jpg';

export const stories = [
  {
    title: 'Ga Sài Gòn',
    date: 'Tháng 10, 2013',
    description:
      'Một cô bé với chiếc quần jean, áo thun, rụt rè, quay mặt, lướt qua anh. Vẻ thơ ngây ấy đã chạm vào trái tim anh. Dù rất muốn hỏi em là ai, em đến đây để làm chi và em có còn đến nữa chăng? Nhưng không, anh đã lỡ 1 chuyến đò ngang.',
    image: Story1,
  },
  {
    title: 'Qua Sông',
    date: 'Tháng 1, 2014',
    description:
      'Thu sang, Đông đến, Xuân về. Từng giọt nắng chiếu xuống hết con đường. Anh thấy lá yêu hoa. Và thấy anh cũng yêu em rồi. Chúng ta chính thức quen nhau!',
    image: Story2,
  },
  {
    title: 'Nước Chảy Đá Mòn',
    date: '2014 -> 2017',
    description:
      'Anh nghĩ quen em là một bài toán dễ. Nhưng anh đã giải sai cách. Thay vì tính biệt số delta để giải phương trình bậc 2 một ẩn, thì anh lại áp dụng định lý Viet (em chưa được học) ==> Anh thất bại! Simple is The Best!',
    image: Story3,
  },
  {
    title: 'Ru Lại Câu Hò',
    date: '2017 -> 2020',
    description:
      'Cứ ngỡ anh là Ngạn, chỉ yêu Hà Lan ở làng Đo Đo. Hiện tại bị quá khứ lu mờ? Không! anh là Zi Fuong, anh yêu Hana của quá khứ lẫn hiện tại.',
    image: Story4,
  },
  {
    title: 'Hơi Thở Của Lửa',
    date: '2020 -> 2022',
    description:
      'Sách vở bảo, Lửa sẽ làm anh tan chảy. Nhưng không, em như thổi một hơi thở ấm áp, phá tan những rỉ sắt trong anh. Biến Huyền Thiết Trọng Kiếm trở nên bất bại! Và mang đến một sức mạnh mới trong anh ~> Chém được Gió! 😂😂😂',
    image: Story5,
  },
  {
    title: 'Chúng Ta Của Sau Này',
    date: '2022 ~>',
    description:
      'Thế thôi! Đưa tay lên nào! Mãi bên nhau bạn nhé! ❤️❤️❤️',
    image: Story6,
  },
];
