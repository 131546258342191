import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import Bride from '@assets/images/hello-hana.jpeg';
import Groom from '@assets/images/hello-zifuong.jpeg';

import { styWrapper } from './styles';

function HelloSection({ isInvitation }) {
  const finalSubtitle = isInvitation ? '03 Oktober 2020, HARRIS Hotel Sentraland, Semarang' : 'Sabtu, 03 Oktober 2020';

  return (
    <Fragment>
      <div id="fh5co-couple" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2 className="main-font">Our destiny</h2>
              {/* <h3 className="sub-title hs">1995 - 1993</h3> */}
              <p className="info">
              Hai người yêu nhau là cái duyên, cái số, dù hợp tuổi hay không hợp tuổi cũng là số trời đã định sẵn, không thể thay đổi. 
               Hơn nữa, cuộc đời một con người còn phù thuộc vào 4 yếu tố “Phúc, Phận, Vận , Mệnh”. 
                Vậy nên, dù là Phận, là Vận hay là Mệnh cũng đều xếp sau chữ Phúc. Đức năng thắng Số. 
                 Sống sao cho tâm luôn trong sạch nhân đức sẽ chiến thắng số mệnh.
              </p>
            </div>
          </div>
          <div className="couple-wrap">
            <div className="couple-half">
              <div className="groom">
                <img src={Bride} alt="groom" className="img-responsive" loading="lazy" />
              </div>
              <div className="desc-groom">
                <h3 className="main-font">Hana, Ng.Ng.</h3>
                <p className="parent-name parent-name__top">
                  Ất Hợi <br/>
                  Sơn Đầu Hỏa
                </p>
              </div>
            </div>
            <p className="heart text-center">
              <i className="icon-heart2"></i>
            </p>
            <div className="and-love">
              <i>&</i>
            </div>
            <div className="couple-half">
              <div className="bride">
                <img src={Groom} alt="groom" className="img-responsive" loading="lazy" />
              </div>
              <div className="desc-bride">
                <h3 className="main-font">Zi Fuong, Ng.</h3>
                <p className="parent-name">
                  Quý Dậu <br/>
                  Kiếm Phong Kim
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {!isInvitation ? (
        <div id="fh5co-couple" className="fh5co-section-gray" css={styWrapper}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2 className="main-font">Seputar Covid-19</h2>
                <p className="info">
                  Berkaitan dengan kondisi pandemik saat ini, keberlangsungan acara kami mengacu pada pedoman
                  Kementerian Kesehatan, yaitu memberlakukan pembatasan jarak dan pembatasan jumlah tamu undangan.
                </p>
                <p className="info">
                  Maka dari itu, dengan segala kerendahan hati, kami mohon maaf jika belum bisa mengundang saudara/i
                  dihari bahagia kami. Sekiranya do'a dari saudara/i sudah lebih dari cukup bagi kami. Semoga kita semua
                  selalu dalam lindungan Allah SWT. Aamiin ya rabbal allamiin..
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null} */}
    </Fragment>
  );
}

HelloSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default HelloSection;
