import Thanglc from '@assets/images/w-thanglc.jpg';
import ThaiNguyen from '@assets/images/w-thainguyen.jpg';
import Mjlo from '@/assets/images/w-mjlohuynh.jpg';
import LinhSilver from '@/assets/images/w-linhsilver.jpg';
import HoDiep from '@/assets/images/w-hodiep.jpg';
import PinPin from '@/assets/images/w-pin.jpg';
import BaoHan from '@/assets/images/w-baohan.png';
import GiaHan from '@/assets/images/w-giahan.png';
import ThinhCoc from '@/assets/images/w-thinhcoc.jpg';
import QuocChung from '@/assets/images/w-quocchung.jpg';
import KimLanh from '@/assets/images/w-kimlanh.jpg';
import ThuyQuyen from '@/assets/images/w-thuyquyen.jpg';
import DatC from '@/assets/images/w-datc.jpg';
import MinhLQ from '@/assets/images/w-minhlq.jpg';
import TinDao from '@/assets/images/w-daotin.jpg';
import HuynhThanh from '@/assets/images/w-huynhthanh.jpg';



export const wishlist = [
  {
    name: 'Ho Diep',
    infoName: 'tFAm',
    description: `Gửi tất cả những lời chúc tốt đẹp
     đến cuộc sống sau này của đôi bạn chẻ! ❤️`,
    image: HoDiep,
  },
  
  {
    name: 'Mjlo Huynh',
    infoName: 'tFAm',
    description: `Trong 7 tỷ người mới có 1 người yêu mình. 
    Thật may mắn và hạnh phúc 
    khi hai bạn đã tìm thấy nhau trong cuộc đời. 
    🌷 Chúc hai bạn mãi vẹn tròn như vậy! 🌷 `,
    image: Mjlo,
  },
  {
    name: 'Thuy Quyen',
    infoName: 'tFAm',
    description: `Mình chúc và tin rằng hai bạn sẽ luôn yêu thương và hạnh phúc bên nhau, chúc hai bạn sẽ có thật nhiều trải nghiệm tuyệt vời trong hành trình phía trước, dù cho là bình yên hay khó khăn thử thách sẽ luôn nắm tay cùng nhau đi qua và mỗi ngày càng yêu nhau nhiều hơn. ❤️`,
    image: ThuyQuyen,
  },
  {
    name: 'Le Cao Thang',
    infoName: 'tFAm',
    description: `Phương với Hân như phân toả hương!🥰🤗`,
    image: Thanglc,
  },
  {
    name: 'Kim Lanh',
    infoName: 'ZF',
    description: `Tình yêu vốn dĩ là bản chất tự nhiên và luôn có sẵn bên trong mỗi người. Một số người tự tìm cách để làm chúng tự hiển lộ, một số người cần có tác nhân để chúng hiển hộ. 
    Đối với anh ấy, đó chính là Bạn. Bạn là ngọn nến thắp sáng tình yêu thương ấy, cùng nhau lan toả  tình yêu nhé! ❤️`,
    image: KimLanh,
  },
  {
    name: 'Huynh Thanh',
    infoName: 'FH',
    description: `Tình một chữ do duyên do nợ.
    Xui khiến Hân gặp gỡ được Phương. 
    Mặt thì xấu, người toàn xương. 
    Nhưng được cái sống yêu thương hết mình. 
    Cả đời trọn một chữ tình. 
    Nên đừng để Phương một mình nha Hân. 
    Hãy luôn săn sóc ân cần.
    Trọn tình trọn nghĩa vượt ngàn khó khăn.
    ...❤️...
    Chúc cho Hân Phương trọn tình trọn nghĩa.
    ...❤️...
    `,
    image: HuynhThanh,
  },
  {
    name: 'Linh Silver',
    infoName: 'FH',
    description: `Con thuyền đã đi rất xa, vượt qua bao con sóng. 
    Phía trước sẽ còn nhiều con sóng cao hơn, 
    chúc con thuyền vững tay lái khám phá muôn vàn kho báu 
    và cập bến hòn đảo yên bình. ❤️`,
    image: LinhSilver,
  },
  {
    name: 'Lavie Nguyen',
    infoName: 'MTT',
    description: `❤️ Lửa gần Kim lâu ngày cũng chớm.
    Chúc tình cảm của anh chị bền như KIM (cương), 
    và nóng bỏng như HỎA 😊!`,
    image: GiaHan,
  },
  {
    name: 'Thai Nguyen',
    infoName: 'MTT',
    description: `Chúc anh chị mãi hạnh phúc vậy nha! ❤️😘`,
    image: ThaiNguyen,
  },
  {
    name: 'Bao Han',
    infoName: 'CR',
    description: `Chúc “bà chị già” của "mị" có thật nhiều niềm vui, hạnh phúc
     và sớm “rước anh Phương về đội” nha! ❤️`,
    image: BaoHan,
  },
  {
    name: 'Pin Pin',
    infoName: 'CR',
    description: `Chúc a chị sớm về 1 nhà! ❤️`,
    image: PinPin,
  },
  {
    name: 'A Tin Dao',
    infoName: 'PS',
    description: `❤️ Chúc hai đồng chí trăm năm hạnh phúc. Thân ái và quyết thắng! ❤️`,
    image: TinDao,
  },
  {
    name: 'A Thinh Coc',
    infoName: 'PS',
    description: `Chúc chúng mày hay ăn đẻ nhiều! 🥰`,
    image: ThinhCoc,
  },
  {
    name: 'A Quoc Chung',
    infoName: 'PS',
    description: `Đường còn dài và còn chông gai,
     chúc hai em cùng nắm tay nhau bước qua hết nhé 💞`,
    image: QuocChung,
  },
  {
    name: 'Minh LQ',
    infoName: 'PS',
    description: `Viettel thân tặng đồng chí Phương cho chị Hân,
     chúc chị luôn hạnh phúc với món quá này 😄`,
    image: MinhLQ,
  },
  {
    name: 'Dat C',
    infoName: 'PS',
    description: `Chúc cho ngọn lửa tình yêu luôn bập bùng cháy mãi ❤️
     Sớm về chung một nhà héhé`,
    image: DatC,
  },
];
